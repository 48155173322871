<template>
	<div>
		<el-dialog
			:title="title"
			:visible.sync="open"
			width="800px"
			@close="commitOnClose"
		>
			<el-form
				ref="saveOrEditForm"
				:model="saveData"
				style="width:700px"
				:rules="rules"
				label-width="160px"
			>
				<el-form-item label="">
					<div class="images-box">
						<div class="images-item">
							<el-form-item prop="accountImg">
								<OssUpload
									@change="getAccountImg"
									suffix="gif;jpg;png;jpeg"
									ocrType="BANK_CARD"
									:isUpdate="update"
									:isOneImg="true"
								></OssUpload>
								<div class="images-title">
									<span>*</span>结算卡照片（带卡号面）
								</div>
							</el-form-item>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="开户名">
					{{ saveData.accountName }}
				</el-form-item>
				<el-form-item label="结算人身份证号">
					{{ maskIDNumber(saveData.accountIdCard) }}
				</el-form-item>
				<el-form-item label="结算账户" prop="accountNo">
					<el-input
						type="tel"
						v-model="saveData.accountNo"
						placeholder="请输入结算账号"
					></el-input>
				</el-form-item>
				<el-form-item label="开户行" prop="bankName">
					<el-select
						style="width:400px"
						v-model="saveData.bankName"
						filterable
						remote
						reserve-keyword
						placeholder="请输入开户银行"
						:remote-method="remoteMethod"
						@change="getBank"
						:loading="loading"
					>
						<el-option
							v-for="item in options"
							:key="item.id"
							:label="item.name"
							:value="item"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开户地区">
					<div style="float:left">
						<el-form-item prop="accountProvince">
							<el-select
								v-model="saveData.accountProvince"
								placeholder="请选择省份"
								@change="provinceChange"
							>
								<el-option
									v-for="item in provinceList"
									:key="item.code"
									:label="item.name"
									:value="item.code"
								></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div style="float:left; padding:0 10px" :span="1">-</div>
					<div style="float:left">
						<el-form-item prop="accountCity">
							<el-select
								v-model="saveData.accountCity"
								placeholder="请选择城市"
							>
								<el-option
									v-for="item in cityList"
									:key="item.code"
									:label="item.name"
									:value="item.code"
								></el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-form-item>
				<el-form-item label="开户支行" prop="branchName">
					<el-select
						style="width:400px"
						@focus="checkPC"
						v-model="saveData.branchName"
						filterable
						remote
						reserve-keyword
						placeholder="请输入开户支行"
						:remote-method="branchRemoteMethod"
						@change="getCnaps"
						:loading="loading"
					>
						<el-option
							v-for="item in branchOptions"
							:key="item.id"
							:label="item.name"
							:value="item"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="银行卡预留手机号" prop="accountMobileNo">
					<el-input
						type="tel"
						v-model="saveData.accountMobileNo"
						placeholder="请输入银行卡预留手机号"
						maxlength="11"
					></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input type="tel" v-model="saveData.remark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitCheck"
					>确定新增</el-button
				>
				<el-button type="primary" @click="cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { CommonApi, ProfitManage } from "@/api";
import { mapState } from "vuex";
export default {
	name: "CustomForm",
	props: {
		onFresh: {
			type: Boolean,
			default: false
		},
		selectRow: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		var checkAccountImg = (rule, value, callback) => {
			if (!this.saveData.accountImg) {
				return callback(new Error("请上传照片"));
			} else {
				value = this.saveData.accountImg;
				callback();
			}
		};
		return {
			title: "变更申请",
			saveData: {
				accountProvince: "",
				accountCity: "",
				bankName: "",
				branchName: ""
			},
			options: [],
			branchOptions: [],
			open: false,
			merchantNo: "",
			rules: {
				accountImg: [{ validator: checkAccountImg, trigger: "change" }],
				accountNo: [
					{
						required: true,
						message: "请输入结算账户",
						trigger: "blur"
					}
				],
				accountProvince: [
					{ required: true, message: "请选择省份", trigger: "change" }
				],
				accountCity: [
					{ required: true, message: "请选择城市", trigger: "change" }
				],
				bankName: [
					{
						required: true,
						message: "请选择开户行",
						trigger: "change"
					}
				],
				branchName: [
					{
						required: true,
						message: "请选择开户支行",
						trigger: "change"
					}
				],
				accountMobileNo: [
					{
						required: true,
						message: "请输入银行预留手机号",
						trigger: "change"
					}
				]
			},
			loading: "",
			cityList: [],
			update: ""
		};
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
			if (value) {
				this.resetData();
			}
		}
	},
	computed: {
		...mapState("app", ["provinceCityArea"]),
		provinceList() {
			return "" || this.provinceCityArea;
		}
	},
	methods: {
		async resetData() {
			this.saveData.accountIdCard = this.selectRow.accountIdCard;
			this.saveData.accountName = this.selectRow.accountName;
		},
		submitCheck() {
			this.$refs.saveOrEditForm.validate(valid => {
				if (valid) {
					this.submitForm();
				} else {
					return false;
				}
			});
		},
		async submitForm() {
			let result = await ProfitManage.myinfo.updateSetter(this.saveData);
			if (result.success) {
				this.Message.success("变更成功");
				this.commitOnClose();
			}
		},
		cancel() {
			this.saveData = {};
			this.commitOnClose();
		},
		getAccountImg(fileList, filename, ocrData) {
			if (JSON.stringify(ocrData) != "{}") {
				this.$set(
					this.saveData,
					"accountNo",
					ocrData.bank_card_number.replace(/\s*/g, "")
				);
				this.$set(this.saveData, "bankName", ocrData.bank_name);
				if (ocrData.bank_name) {
					this.remoteMethod(ocrData.bank_name, "ocr");
				}
			}
			this.saveData.accountImg = filename;
		},
		provinceChange(val) {
			this.saveData.accountCity = "";
			this.provinceList.map(e => {
				if (val == e.code) {
					this.cityList = e.children;
				}
			});
		},
		async remoteMethod(query, ocr) {
			if (query != "") {
				let res = await CommonApi.bankList({ name: query });
				if (res.success) {
					this.options = res.data;
					if (ocr) {
						this.saveData.bankName = this.options[0].name;
						this.saveData.bankCode = this.options[0].code;
						this.saveData.branchName = "";
						this.saveData.cnapsNo = "";
						this.branchOptions = [];
					}
				}
			} else {
				this.options = [];
			}
		},
		async branchRemoteMethod(query) {
			if (query != "") {
				let params = {
					provinceCode: this.saveData.accountProvince,
					cityCode: this.saveData.accountCity,
					bankCode: this.saveData.bankCode,
					name: query
				};
				let res = await CommonApi.branchList(params);
				if (res.success) {
					this.branchOptions = res.data;
				}
			} else {
				this.branchOptions = [];
			}
		},
		checkPC() {
			if (!this.saveData.accountCity) {
				this.Message.error("开户行城市不能为空");
				return;
			}
			if (!this.saveData.bankName) {
				this.Message.error("开户银行不能为空");
				return;
			}
		},
		getCnaps(e) {
			this.saveData.branchName = e.name;
			this.saveData.cnapsNo = e.code;
		},
		getBank(e) {
			this.saveData.bankName = e.name;
			this.saveData.bankCode = e.code;
			this.saveData.branchName = "";
			this.saveData.cnapsNo = "";
			this.branchOptions = [];
		},
		commitOnClose() {
			this.$emit("on-close");
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		}
	}
};
</script>

<style scoped></style>
